import * as React from 'react'
import queryString from 'query-string'

import JobSidebar from '../../components/jobSidebar'
import SEO from '../../components/seo/seo'
import HomeInspect from '../../components/homeowner/HomeInspect'

const IndexPage = () => {


	return (

		typeof window !== 'undefined' ? <iframe
			style={{ width: '100%', height: '100vh', marginBottom: 0 }}
			src={'https://fairmarket.renoworks.com/pages/project?project=' + queryString.parse(location.search).renoworks_display_id}
		/> : null
	)
}

export default IndexPage
